@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@layer utilities {
  * {
    scroll-behavior: smooth;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
  }
  .bg-gradient-light {
    background: rgb(113, 255, 255);
    background: linear-gradient(
      188deg,
      rgb(113, 172, 255) 0%,
      rgb(167, 204, 255) 20%,
      rgb(222, 222, 222) 80%
    );
    position: relative;
    z-index: 0;
  }
  body {
    background: rgba(4, 28, 50);
    /* background: rgb(222, 222, 222); */
    position: relative;
    z-index: 0;
  }
  .bg-gradient-dark {
    background: rgb(11, 94, 160);
    background: linear-gradient(
      188deg,
      rgb(6, 70, 99) 0%,
      rgb(4, 41, 58) 30%,
      rgba(4, 28, 50, 1) 80%
    );
    position: relative;
    z-index: 0;
  }
  .dark body {
    background: rgba(4, 28, 50);
    position: relative;
    z-index: 0;
  }
  .customScrollbar::-webkit-scrollbar {
    width: 0.75rem;
    height: 0.6rem;
  }
  .customScrollbar::-webkit-scrollbar-track {
    background-color: #edf2f7;
  }
  .customScrollbar::-webkit-scrollbar-thumb {
    background-color: #718096;
    border-radius: 9999px;
  }
  @media (min-width: 320px) {
    .container {
      max-width: 900px;
    }
  }
  @media (min-width: 1024px) {
    .container {
      max-width: 1280px;
    }
  }
}

/* Define the CSS for the route transitions */
.fade-enter {
  opacity: 0;
  transform: scale(0.95);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

.portfolio-enter {
  opacity: 0;
  transform: scale(0.9);
}
.portfolio-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.portfolio-exit {
  opacity: 1;
  transform: scale(1);
}
.portfolio-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
